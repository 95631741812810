import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  margin: 90px 0 60px 0;
  @media only screen and (max-width: 480px) {
    margin: 50px 0 30px 0;
  }

  h2 {
    font-size: 34px;
    font-weight: 700;
    color: ${themeGet("colors.mainBlue")};
    @media only screen and (max-width: 480px) {
      font-size: 26px;
    }
  }

  p {
    margin-top: 0px !important;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 30px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
      margin-bottom: 8px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  ul {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 24px;

    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    li {
      font-weight: 300;
      margin-bottom: 4px;
      list-style-type: disc;
      list-style-position: inside;
      padding-left: 26px;
      text-indent: -26px;
      @media only screen and (max-width: 990px) {
        margin: 0px 0;
      }
      @media only screen and (max-width: 990px) {
        padding-left: 23px;
        text-indent: -23px;
      }
    }
  }
`

export const StyledImg = styled.div`
  width: 426px;
  float: ${({ position }) => (position === "Right" ? "right" : "left")};
  margin: ${({ position }) =>
    position === "Right" ? "0 0 32px 120px" : "0 120px 32px 0"};
  @media only screen and (max-width: 990px) {
    margin: ${({ position }) =>
      position === "Right" ? "0 0 16px 40px" : "0 40px 16px 0"};
  }
  @media only screen and (max-width: 480px) {
    margin: ${({ position }) =>
      position === "Right" ? "0 0 -14px 14px" : "0 14px -14px 0"};
  }
  @media only screen and (max-width: 800px) {
    width: 200px;
  }
  @media only screen and (max-width: 480px) {
    width: 120px;
  }

  .image {
    object-fit: cover;
    @media only screen and (max-width: 635px) {
      margin-bottom: 24px;
    }
  }
`

export const ItemContainer = styled.div`
  margin-bottom: ${({ isASingleColumn, index }) =>
    isASingleColumn === true && index === 0 ? "0px" : "70px"};
  @media only screen and (max-width: 480px) {
    margin-bottom: ${({ isASingleColumn, index }) =>
      isASingleColumn === true && index === 0 ? "0px" : "40px"};
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`

export default SectionWrapper
