import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 60px 0 60px;

  @media only screen and (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media only screen and (max-width: 480px) {
    padding: 30px 0 30px;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .sec {
    margin-top: 32px;
    text-transform: inherit;
    border-radius: 0px;
    padding: 22px 76px;
    font-size: 20px;
    background-color: transparent;
    color: ${themeGet("colors.mainBlue")};
    border: 1px solid ${themeGet("colors.mainBlue")};

    @media only screen and (max-width: 990px) {
      display: none;
    }
    &.sec {
      &:hover {
        background-color: ${themeGet("colors.mainBlue")};
        border: 1px solid ${themeGet("colors.mainBlue")};
        color: ${themeGet("colors.white")};
      }
    }
    &.text {
      .btn-icon {
        svg {
          width: auto;
          height: 25px;
        }
      }
      &:hover {
        color: ${themeGet("colors.primaryHover")};
      }
    }
  }
`

export const ContentWrapper = styled.div`
  .hello {
    margin: 0 auto;
    color: red;
  }
  .infinite {
    display: flex;
    gap: 37px;
    flex-wrap: wrap;
    @media only screen and (max-width: 990px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 480px) {
      gap: 66px;
      margin-bottom: 40px;
    }
    .content {
      padding: 40px 20px;
      @media only screen and (max-width: 480px) {
        padding: 30px;
      }
    }
    .blog_post {
      background-color: ${themeGet("colors.lightGrey")};
      flex: 0 0 calc(33.3333% - 25px);

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media only screen and (max-width: 1440px) {
        flex: 0 0 calc(50% - 19px);
      }
      @media only screen and (max-width: 990px) {
        flex: 0 0 100%;
        margin-right: 0;
        margin-top: 0px;
      }

      .thumbnail {
        position: relative;
        max-height: 269px;
      }

      .post-details {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        height: 100%;
        @media only screen and (max-width: 990px) {
          padding: 20px 30px;
        }

        .tag {
          display: inline-block;
          font-family: "Open Sans";
          font-weight: 700;
          margin: 0 8px 8px 0;
          color: ${themeGet("colors.white")};
          background-color: ${themeGet("colors.mainBlue")};
          text-transform: uppercase;
          font-size: 14px;
          padding: 0px 16px;
          border-radius: 20px;
        }

        .author {
          display: flex;
          align-items: center;
          .author-avatar {
            width: 48px;
            border-radius: 50%;
            margin-right: 24px;
          }
          p {
            color: ${themeGet("colors.white")};
            margin: 0;
          }
          .author-name {
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 20px;
          }
          .author-occupation {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .overlay {
        background-color: rgba(0, 0, 0, 0.7);
      }
      .image {
        flex-shrink: 0;
        min-width: 100%;
        height: 269px;
        opacity: 0.4;
      }
      .arrow {
        width: 25px;
      }
      .details {
        display: flex;
        justify-content: space-between;

        .category {
          font-family: "Open Sans";
          font-weight: 700;
          color: ${themeGet("colors.mainBlue")};
          font-size: 16px;
          text-transform: uppercase;
          margin: 0 16px 0 0;
          @media only screen and (max-width: 990px) {
            font-size: 14px;
          }
        }

        .date {
          font-weight: 300;
          color: ${themeGet("colors.black")};
          font-size: 16px;
          text-transform: uppercase;
          margin: 0;
          @media only screen and (max-width: 990px) {
            font-size: 14px;
          }
        }
      }
      .category-details {
        display: flex;
        align-items: center;
      }
      .seperator {
        border-top: 2px solid ${themeGet("colors.mainBlue")};
        width: 32px;
        margin-left: 0;
      }
      h3 {
        color: ${themeGet("colors.black")};
        font-size: 22px;
        line-height: 30px;
        margin: 18px 0px;
        @media only screen and (max-width: 768px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 20px;
          line-height: 28px;
        }
      }

      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: ${themeGet("colors.black")};
        margin-bottom: 0px;
        @media only screen and (max-width: 990px) {
          font-size: 16px;
        }
      }
      .excerptLink {
        color: ${themeGet("colors.black")};
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        padding: 30px 20px;
        border-top: 1px solid #d0cfd0;
        @media only screen and (max-width: 990px) {
          font-size: 16px;
          padding: 30px 30px;
        }
        img {
          margin-left: auto;
        }
        i {
          margin-left: 2px;
          transition: 0.3s ease 0s;
        }
      }
    }
  }
`

export default SectionWrapper
