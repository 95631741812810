import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BannerWrapper = styled.div`
  margin-top: 70px;
  height: 680px;
  overflow: hidden;
  background-image: ${props => props.image && `url(${props.image})`};
  background-color: ${themeGet("colors.black")};
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  @media only screen and (max-width: 1337px) {
    margin-top: 56px;
  }
  @media only screen and (max-width: 1099px) {
    min-height: auto;
  }
`

export const BannerContent = styled.div`
  width: 100%;

  @media only screen and (max-width: 991px) {
    flex-shrink: 0;
  }

  h1 {
    font-size: 70px;
    line-height: 60px;
    font-weight: 700;
    color: ${themeGet("colors.white")};
    margin-bottom: 32px;
    margin-top: 100px;
    line-height: 80px;
    @media only screen and (max-width: 990px) {
      line-height: 60px;
      font-size: 50px;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 110px;
      line-height: 44px;
      font-size: 40px;
    }
  }

  p {
    max-width: 630px;
    color: ${themeGet("colors.white")};
    font-size: 28px;
    line-height: 40px;
    margin-top: 48px;

    @media only screen and (max-width: 990px) {
      margin-top: 40px;
      font-size: 24px;
      line-height: 36px;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 24px;
      font-size: 20px;
      line-height: 36px;
    }
  }
`

export const ButtonGroup = styled.div`
  margin-top: 60px;
  @media only screen and (max-width: 480px) {
    margin-top: 40px;
    display: flex;
  }

  .primary {
    margin-right: 32px;
    text-transform: inherit;
    border-radius: 0px;
    padding: 24px 50px;
    font-size: 20px;
    background-color: ${themeGet("colors.white")};
    color: ${themeGet("colors.black")};
    border: 1px solid ${themeGet("colors.white")};
    @media only screen and (max-width: 480px) {
      margin-bottom: 20px;
      padding: 15px 10px;
      font-size: 16px;
    }
    &.primary {
      &:hover {
        background-color: transparent;
        border: 1px solid ${themeGet("colors.white")};
        color: ${themeGet("colors.white")};
      }
    }
  }

  &.text {
    .btn-icon {
      svg {
        width: auto;
        height: 25px;
      }
    }
    &:hover {
      color: ${themeGet("colors.primaryHover")};
    }
  }

  .sec {
    text-transform: inherit;
    border-radius: 0px;
    padding: 24px 50px;
    font-size: 20px;
    background-color: transparent;
    border: 1px solid ${themeGet("colors.white")};
    @media only screen and (max-width: 480px) {
      margin-bottom: 20px;
      padding: 15px 10px;
      font-size: 16px;
    }
    &.sec {
      &:hover {
        background-color: ${themeGet("colors.mainBlue")};
        border: 1px solid ${themeGet("colors.mainBlue")};
      }
    }
    &.text {
      .btn-icon {
        svg {
          width: auto;
          height: 25px;
        }
      }
      &:hover {
        color: ${themeGet("colors.primaryHover")};
      }
    }
  }
`

export const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  > div.container {
    display: flex;
  }
`

export default BannerWrapper
