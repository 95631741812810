/* eslint-disable */

import React from "react"

import Consultation from "../../../../containers/Consultation"
import Container from "../../../../components/UI/Container"
import SectionWrapper, { Columns, SectionWrapperAlt } from "./twoColumns.style"

const CasesTwoColumnsText = ({ content, location }) => {
  const { column_a_text, column_b_text } = content.primary

  return (
    <>
      <SectionWrapper>
        <Container width="1220px">
          <Columns>
            <div dangerouslySetInnerHTML={{ __html: column_a_text.html }} />
            <div dangerouslySetInnerHTML={{ __html: column_b_text.html }} />
          </Columns>
        </Container>
      </SectionWrapper>
      <SectionWrapperAlt>
        <Container width="1220px" className="containerAlt">
          <Consultation pathname={location.pathname} colorReverse />
        </Container>
      </SectionWrapperAlt>
    </>
  )
}

export default CasesTwoColumnsText
