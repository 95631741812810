import React, { Fragment } from "react"

import PropTypes from "prop-types"
import Box from "../../../../components/Box"
import Heading from "../../../../components/Heading"
import Container from "../../../../components/UI/Container"
import TitleSection from "../../../../containers/TitleSection"
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "../../../../components/Accordion"
import { Icon } from "react-icons-kit"
import { plus } from "react-icons-kit/entypo/plus"
import { minus } from "react-icons-kit/entypo/minus"
import FaqSectionWrapper from "./faqSection.style"
import Text from "../../../../components/Text"

const FaqSection = ({ titleStyle, faqRow, content }) => {
  const { primary, items } = content

  return (
    <FaqSectionWrapper>
      <Container width="1220px">
        <Box className="questionsRow">
          <TitleSection
            titleA={primary.title1.html}
            titleB={primary.title_colored.html}
          />
          <div className="details">
            <Text
              content="Have other questions? Email us!"
              className="question"
            />
            <Text content="sales@alpacked.io" className="email" />
          </div>
        </Box>
        <Box {...faqRow}>
          <Accordion>
            <Fragment>
              {items.map((faqItem, index) => (
                <AccordionItem key={index} expanded={faqItem.expend}>
                  <Fragment>
                    <AccordionTitle>
                      <Fragment>
                        <Heading
                          as="p"
                          content={faqItem.question.text}
                          {...titleStyle}
                        />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={minus} size={24} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={plus} size={24} />
                          </CloseIcon>
                        </IconWrapper>
                      </Fragment>
                    </AccordionTitle>
                    <AccordionBody>
                      <div
                        className="faq_content"
                        dangerouslySetInnerHTML={{
                          __html: faqItem.answer.html,
                        }}
                      />
                    </AccordionBody>
                  </Fragment>
                </AccordionItem>
              ))}
            </Fragment>
          </Accordion>
        </Box>
      </Container>
    </FaqSectionWrapper>
  )
}

// FaqSection style props
FaqSection.propTypes = {
  faqRow: PropTypes.object,
  col: PropTypes.object,
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  content: PropTypes.object,
}

// FaqSection default style
FaqSection.defaultProps = {
  // faq row default styles
  faqRow: {
    width: ["100%", "100%", "100%", "50%", "55%"],
    ml: ["0", "0", "0", "5%", "5%"],
  },
  // accordion title default style
  titleStyle: {
    fontSize: ["16px", "16px", "18px", "18px", "18px"],
    fontWeight: "700",
    color: "#000",
    mb: "0",
  },
}

export default FaqSection
