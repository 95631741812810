import React from "react"
import { Link } from "gatsby"

import Arrow from "../../../../common/assets/image/alpacked/contacts/arrow.svg"
import Container from "../../../../components/UI/Container"
import Text from "../../../../components/Text"
import SectionWrapper, { Content } from "./breadcrumbs.style"

const Breadcrumbs = ({ page, breadCrumbsContent }) => {
  return (
    <SectionWrapper>
      <Container width="1220px">
        <Content>
          <Link to="/">
            <Text content="HOME PAGE" className="home" />
          </Link>
          <img loading="lazy" src={Arrow} alt="breadcrumbs-arrow" />
          <Link to={`/${breadCrumbsContent.category.url}/`}>
            <Text
              content={breadCrumbsContent.category.title}
              className="home"
            />
          </Link>
          <img loading="lazy" src={Arrow} alt="breadcrumbs-arrow" />
          <Text
            content={breadCrumbsContent.page.title}
            className="current-page"
          />
        </Content>
      </Container>
    </SectionWrapper>
  )
}

export default Breadcrumbs
