import SectionWrapper, {
  ImageWrapper,
  TextWrapper,
} from "./alpackedBenefits.style"

import Consultation from "../../../Consultation"
import Container from "../../../../components/UI/Container"
import Img from "gatsby-image"
import React from "react"
import Text from "../../../../components/Text"
import TitleSection from "../../../TitleSection"

const AlpackedBenefits = ({ content }) => {
  const { items, primary } = content
  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          addBreak
          reverseColor
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
          subtitle={primary.description}
          titleMaxWidth={
            primary.title_width ? `${primary.title_width}px` : null
          }
        />
      </Container>
      <Container width="1220px" className="contentMain">
        <TextWrapper>
          {items.map((item, index) => {
            return (
              <div key={index} className="details">
                <Text content={item.benefit_title.text} className="title" />
                <hr className="seperator" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description?.html,
                  }}
                  className="content"
                />
                {/* <Text content={item.benefit_description} className="content" /> */}
              </div>
            )
          })}
        </TextWrapper>
        <ImageWrapper>
          <Img
            fadeIn={false}
            loading="lazy"
            className="image"
            fluid={primary.image.localFile?.childImageSharp.fluid}
            alt="mission-img"
          />
        </ImageWrapper>
      </Container>
      <Consultation colorReverse />
    </SectionWrapper>
  )
}

export default AlpackedBenefits
