export const addLazyLoadingToImages = html => {
  const imgRegex = /<img([^>]+)>/g

  return html.replace(imgRegex, match => {
    if (/loading=/.test(match)) {
      return match
    }
    return match.replace("<img", '<img loading="lazy"')
  })
}
