import styled from "styled-components"

const SectionWrapper = styled.div`
  position: relative;
  padding: 60px 0px 0px 0px;
  margin-bottom: 80px;
  min-height: 650px;
  @media only screen and (max-width: 1220px) {
    padding: 50px 0px 0px 0px;
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 1100px) {
    min-height: 0px;
  }
  @media only screen and (max-width: 990px) {
    padding: 40px 0px 0px 0px;
    margin-bottom: 80px;
    min-height: 789px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0px 0px 0px;
    margin-bottom: 50px;
  }
  .content {
    display: flex;
    @media only screen and (max-width: 1100px) {
      flex-direction: column-reverse;
    }
  }
`

export const ImageWrapper = styled.div`
  display: ${props => (props.isImageExist ? "block" : "none")};
  width: ${props => (props.imageScale ? "100%" : "0%")};
  @media only screen and (max-width: 1100px) {
    width: ${props => (props.imageScale ? "100%" : "100%")};
  }
  .gatsby-image-wrapper {
    position: ${props =>
      props.imageScale ? "relative" : "absolute !important"};
    @media only screen and (max-width: 1100px) {
      width: ${props => (props.imageScale ? "100%" : "100%")};
      position: relative !important;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 480px) {
      position: relative !important;
      margin-bottom: 30px;
    }
  }
  .image {
    width: ${props => (props.imageScale ? "100%" : "1028px")};
    height: 455px;
    @media only screen and (max-width: 1100px) {
      width: auto;
      height: auto;
    }
  }
`

export const TextWrapper = styled.div`
  width: ${props =>
    props.isImageExist ? props => (props.imageScale ? "60%" : "41%") : "100%"};
  margin-right: ${props => (props.isImageExist ? "120px" : "0px")};
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    ul,
    ol {
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 48px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 0px;
      }
      li {
        font-weight: 300;
        margin: 10px 0;
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 26px;
        text-indent: -26px;
        @media only screen and (max-width: 990px) {
          margin: 10px 0;
        }
      }
    }
    p {
      margin: 0px 0px 24px 0px;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;

      @media only screen and (max-width: 900px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
        margin: 0px 0px 20px 0px;
        &::last-child {
          margin: 0px 0px 0px 0px;
        }
      }
    }
  }
`

export default SectionWrapper
