import styled from "styled-components"

const FreeContentSectionWrapper = styled.section`
  padding: 0px 0 50px;
  height: auto;
  overflow: hidden;
`

export const ContentWrapper = styled.div`
  padding: 10px 0 10px;
`

export const Content = styled.div`
  .content {
    div {
      padding: 0px;
    }
    img {
      /* flex-shrink: 0; */
      max-width: ${props =>
        props.imagePosition === "center" ? "60%" : "100%"};
      /* min-height: 100%; */
      margin-top: ${props =>
        props.imagePosition === "center" ? "50px" : null};
      @media only screen and (max-width: 480px) {
        max-width: 100%;
      }
    }
    p {
      margin-top: 0px !important;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      @media only screen and (max-width: 990px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
    ul,
    ol {
      overflow: hidden;
      padding: 0px;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 0px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 0px;
      }
      li {
        line-height: 30px;
        font-weight: 300;
        margin: 10px 0;
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 26px;
        text-indent: -26px;
        @media only screen and (max-width: 990px) {
          margin: 10px 0;
        }
      }
    }
    p.block-img {
      display: flex;
      justify-content: center;

      float: ${props =>
        props.imagePosition === "right"
          ? `right`
          : props.imagePosition === "left"
          ? `left`
          : "none"};
      width: ${props =>
        props.imagePosition === "right"
          ? `50%`
          : props.imagePosition === "left"
          ? `50%`
          : "100%"};
      /* margin: 0px 0px 50px 50px; */
      margin: ${props =>
        props.imagePosition === "right"
          ? `0px 0px 40px 40px;`
          : props.imagePosition === "left"
          ? `0px 40px 40px 0px;`
          : "0px auto 30px auto;"};
      @media only screen and (max-width: 990px) {
        float: none;
        width: 100%;
        margin: 30px 0 20px 0 !important;
      }
    }
  }
`
export default FreeContentSectionWrapper
