import styled from "styled-components"

export const CertificationsImage = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 20%;
  margin-bottom: 40px;
  position: relative;
  padding: 0px 18px;
  @media only screen and (max-width: 990px) {
    width: 25%;
    padding: 16px 28px;
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 768px) {
    width: 33%;
    padding: 16px 28px;
  }
  @media only screen and (max-width: 568px) {
    width: 50%;
    margin-bottom: 24px;
    padding: 16px 28px;
  }
  @media only screen and (max-width: 480px) {
    padding: 8px 16px;
  }

  img {
    max-height: 76px;
    max-width: 150px;
    transition: 0.3s ease-in-out;
    @media only screen and (max-width: 568px) {
      max-height: 76px;
      max-width: 100px;
    }
  }
`
