import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  margin: 90px 0 0 0;
  @media only screen and (max-width: 480px) {
    margin: 50px 0 0 0;
  }

  .container {
    @media only screen and (max-width: 480px) {
      padding: 0px !important;
    }
  }
`
export const SectionWrapperAlt = styled.div`
  margin: 0px 0 30px 0;
  @media only screen and (max-width: 480px) {
    margin: 0px 0px 15px 0;
  }

  .containerAlt {
    @media only screen and (max-width: 480px) {
      padding: 0px !important;
    }
  }
`

export const Columns = styled.div`
  display: flex;
  gap: 56px;
  padding: 60px;
  color: ${themeGet("colors.white")};
  background-color: ${themeGet("colors.black")};
  @media only screen and (max-width: 990px) {
    flex-direction: column;
    gap: 40px;
    padding: 40px;
  }
  @media only screen and (max-width: 480px) {
    gap: 30px;
    padding: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${themeGet("colors.white")};
    margin-top: 0px;
    font-size: 22px;
  }

  p {
    color: ${themeGet("colors.white")};
    margin-top: 0px !important;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 28px;
    line-height: 30px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  ul {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 24px;

    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    li {
      font-weight: 300;
      margin-bottom: 4px;
      list-style-type: disc;
      list-style-position: inside;
      padding-left: 26px;
      text-indent: -26px;
      @media only screen and (max-width: 990px) {
        margin: 0px 0;
      }
      @media only screen and (max-width: 990px) {
        padding-left: 23px;
        text-indent: -23px;
      }
    }
  }
`

export default SectionWrapper
