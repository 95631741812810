import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  width: 100%;
  padding: 50px 0 20px;
  z-index: 0;
  @media only screen and (max-width: 1220px) {
    padding: 50px 0 20px;
  }
  @media only screen and (max-width: 990px) {
    padding: 40px 0 10px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0 10px;
  }
`

export const FeatureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 1440px) {
    padding: 0;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 0px;
  }
  @media only screen and (max-width: 991px) {
    gap: 0px;
    justify-content: space-between;
  }

  .service_post {
    /* width: calc(100% / 3 - 22px); */
    width: ${props =>
      props.numberOfColumns % 2 === 0
        ? `calc(100% / 4 - 15px);`
        : `calc(100% / 3 - 14px);`};
    padding: 48px 20px;
    background-color: ${themeGet("colors.lightBlue")};
    text-align: left;
    min-height: 300px;

    @media only screen and (max-width: 1200px) {
      width: ${props =>
        props.numberOfColumns % 2 === 0
          ? `calc(100% / 3 - 18px);`
          : `calc(100% / 3 - 18px);`};
      padding: 30px 20px;
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 991px) {
      width: ${props =>
        props.numberOfColumns % 2 === 0
          ? `calc(100% / 3 - 18px);`
          : `calc(100% / 2 - 18px);`};
      padding: 30px 20px;
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 767px) {
      width: calc(100%);
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      min-height: 0px;
    }

    .thumbnail {
      img {
        height: 40px;
        width: auto;
        margin-bottom: 24px;
        @media only screen and (max-width: 990px) {
          margin-bottom: 24px;
        }
      }
    }

    .content {
      .title {
        font-size: 22px;
        line-height: 30px;
        font-weight: 700;
        margin-top: 2px;
        margin-bottom: 42px;
        @media only screen and (max-width: 990px) {
          font-size: 20px;
          margin: 0 0 24px;
        }
        @media only screen and (max-width: 480px) {
          line-height: 24px;
          font-size: 20px;
          margin: 0 0 16px;
        }
      }

      .titleAlt {
        margin-top: 5px;
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
        @media only screen and (max-width: 990px) {
          font-size: 20px;
          margin: 0 0 24px;
        }
        @media only screen and (max-width: 480px) {
          line-height: 30px;
          font-size: 20px;
          margin: 0 0 16px;
        }
      }

      ul {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px;
        @media only screen and (max-width: 480px) {
          font-size: 16px;
          margin-bottom: 0px;
        }
        li {
          margin: 4px 0;
          list-style-type: disc;
          list-style-position: inside;
          padding-left: 26px;
          text-indent: -26px;
        }
      }
    }
  }
`
export const Discover = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 60px 0px 60px 160px;
  box-shadow: 0 0 100px rgba(175, 182, 194, 0.2);
  background-color: ${themeGet("colors.mainBlue")};
  font-weight: 700;
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: left;
  @media only screen and (max-width: 990px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
  }
  @media only screen and (max-width: 480px) {
    min-height: 0px;
  }

  .text {
    display: flex;
    align-items: center;
  }

  .plus {
    font-size: 62px;
    margin-right: 8px;
    color: ${themeGet("colors.white")};

    @media only screen and (max-width: 1220px) {
      font-size: 82px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 70px;
      margin-bottom: 16px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 40px;
      margin-bottom: 26px;
      line-height: 40px;
    }
  }

  .count {
    margin-right: 12px;
    font-size: 82px;
    color: ${themeGet("colors.white")};

    @media only screen and (max-width: 1220px) {
      font-size: 82px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 70px;
      margin-bottom: 16px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 40px;
      margin-bottom: 26px;
      line-height: 40px;
      margin-right: 8px;
    }
  }
  .extra {
    font-size: 82px;
    color: #5cc2e8;

    @media only screen and (max-width: 1220px) {
      font-size: 82px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 70px;
      margin-bottom: 16px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 40px;
      margin-bottom: 26px;
      line-height: 40px;
    }
  }

  .button {
    margin-right: -1px;
    width: 451px;
    font-weight: 400;
    margin-left: auto;
    text-transform: inherit;
    border-radius: 0px;
    padding: 31px 50px 31px 40px;
    font-size: 20px;
    background-color: transparent;
    border: 1px solid ${themeGet("colors.white")};
    @media only screen and (max-width: 480px) {
      padding: 18px 20px;
    }
    &.button {
      &:hover {
        background-color: ${themeGet("colors.mainBlueHover")};
        border: 1px solid ${themeGet("colors.white")};
      }
    }
    &.text {
      .btn-icon {
        svg {
          width: auto;
          height: 25px;
        }
      }
      &:hover {
        color: ${themeGet("colors.primaryHover")};
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 30px 30px;
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`

export default SectionWrapper
