import React from "react"
import striptags from "striptags"

import Container from "../../../../components/UI/Container"
import Heading from "../../../../components/Heading"
import Text from "../../../../components/Text"
import { CounterItem, CounterWrapper } from "./statistics.style"

const Statistics = ({ content }) => {
  return (
    <Container width="1220px">
      <CounterWrapper>
        {content.items.map((item, index) => {
          return (
            <CounterItem key={index}>
              <Heading as="h2" content={striptags(item.title.html)} />
              <Text content={item.subtitle} />
            </CounterItem>
          )
        })}
      </CounterWrapper>
    </Container>
  )
}

export default Statistics
