import AnchorLink from "react-anchor-link-smooth-scroll"
import React from "react"
import striptags from "striptags"

const TitlesZone = ({ titles }) => {
  let counter = 0

  const title = titles.map((s, index) => {
    switch (s.slice_type) {
      // These are the API IDs of the slices
      case "benefits1": {
        counter = counter + 1
        return (
          <AnchorLink
            key={index}
            className="smooth_scroll"
            href={`#${striptags(s.primary.title1.html)}${striptags(
              s.primary.title_colored.html
            )}`}
            offset="20"
          >
            <li>
              <span>{counter < 10 ? `0${counter}` : counter}.</span>
              {striptags(s.primary.title1.html)}{" "}
              {striptags(s.primary.title_colored.html)}
            </li>
          </AnchorLink>
        )
      }
      case "servicesAlt": {
        counter = counter + 1
        return (
          <AnchorLink
            key={index}
            className="smooth_scroll"
            href={`#${striptags(s.primary.title1.html)}${striptags(
              s.primary.title_colored.html
            )}`}
            offset="60"
          >
            <li>
              <span>{counter < 10 ? `0${counter}` : counter}.</span>
              {striptags(s.primary.title1.html)}{" "}
              {striptags(s.primary.title_colored.html)}
            </li>
          </AnchorLink>
        )
      }
      case "table": {
        counter = counter + 1
        return (
          <AnchorLink
            key={index}
            className="smooth_scroll"
            href={`#${striptags(s.primary.title1.html)}${striptags(
              s.primary.title_colored.html
            )}`}
            offset="60"
          >
            <li>
              <span>{counter < 10 ? `0${counter}` : counter}.</span>
              {striptags(s.primary.title1.html)}{" "}
              {striptags(s.primary.title_colored.html)}
            </li>
          </AnchorLink>
        )
      }
      case "content": {
        return (
          <div key={index}>
            {s.items.map((title, index) => {
              if (title.title1.html) {
                counter = counter + 1
              }
              return (
                <div key={index}>
                  {title.title1.html &&
                  title.title1.html.split("").length > 9 ? (
                    <AnchorLink
                      className="smooth_scroll"
                      href={`#${striptags(title.title1.html)}${striptags(
                        title.title_colored.html
                      )}`}
                      offset="60"
                      key={index}
                    >
                      <li>
                        <span>{counter < 10 ? `0${counter}` : counter}.</span>
                        <span
                          style={{ fontWeight: "normal" }}
                          dangerouslySetInnerHTML={{
                            __html: `${striptags(
                              title.title1.html
                            )} ${striptags(title.title_colored.html)}`,
                          }}
                        />
                      </li>
                    </AnchorLink>
                  ) : null}
                </div>
              )
            })}
          </div>
        )
      }
      default:
        return null
    }
  })

  return <>{title}</>
}

export default TitlesZone
