import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  background-color: ${themeGet("colors.black")};
  padding: 100px 0px 0px 0px;
  margin: 60px 0px 60px 0px;
  @media only screen and (max-width: 1220px) {
    padding: 100px 0px 0px 0px;
    margin: 50px 0px 50px 0px;
  }
  @media only screen and (max-width: 990px) {
    padding: 80px 0px 0px 0px;
    margin: 40px 0px 40px 0px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0px 0px 0px;
    margin: 25px 0px 25px 0px;
  }
  .contentMain {
    position: relative;
    display: flex;
    padding-bottom: 160px;
    @media only screen and (max-width: 1200px) {
      padding-bottom: 120px;
    }
    @media only screen and (max-width: 1100px) {
      flex-direction: column-reverse;
    }
    @media only screen and (max-width: 990px) {
      padding-bottom: 80px;
    }
    @media only screen and (max-width: 990px) {
      padding-bottom: 40px;
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  left: 58%;
  top: -50%;
  transform: translate(-58%, 2%);
  max-height: 645px;
  margin-top: -8px;
  .image {
    width: 1031px;
    height: 638px;
  }
  @media only screen and (max-width: 1440px) {
    left: 33%;
    top: -50%;
    transform: translate(-50%, 4%);
    .image {
      width: 600px;
      height: 638px;
    }
  }

  @media only screen and (max-width: 1100px) {
    display: block;
    left: 0;
    top: none;
    transform: none;
    margin-bottom: 30px;
    .image {
      width: 100%;
    }
  }
  @media only screen and (max-width: 768px) {
    display: block;
    left: 0;
    top: none;
    transform: none;
    margin-bottom: 30px;
    .image {
      width: 100%;
      height: 300px;
    }
  }
`

export const TextWrapper = styled.div`
  width: 45%;
  /* padding-bottom: 120px; */
  & .details:last-child {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 1100px) {
    padding-left: 0;
    width: 100%;
    padding-bottom: 50px;
  }

  .details {
    margin-bottom: 48px;
    @media only screen and (max-width: 990px) {
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 40px;
    }
  }

  .title {
    color: ${themeGet("colors.white")};
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .content {
    width: 539px;
    p {
      color: ${themeGet("colors.white")};
      font-size: 18px;
      font-weight: 300;
    }
    @media only screen and (max-width: 990px) {
      font-size: 18px;
      width: auto;
    }

    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
      width: auto;
    }
  }

  .seperator {
    border: 1px solid ${themeGet("colors.mainBlue")};
    margin: 8px 0 16px 0px;
    width: 40px;
    margin-left: 0;
    border-radius: 0px;
    background: ${themeGet("colors.mainBlue")};
  }
`

export default SectionWrapper
