import React from "react"
import striptags from "striptags"
import { Link } from "gatsby"

import Button from "../../../../components/Button"
import Container from "../../../../components/UI/Container"
import Heading from "../../../../components/Heading"
import Text from "../../../../components/Text"
import BannerWrapper, {
  BannerContent,
  ButtonGroup,
  Overlay,
} from "./banner.style"

const Banner = ({ content }) => {
  const {
    image,
    subtitle,
    title,
    button_a_link,
    button_a_title,
    button_b_link,
    button_b_title,
  } = content.items[0]
  return (
    <BannerWrapper image={image.url}>
      <Overlay>
        <Container width="1220px">
          <BannerContent haveButtons={button_a_title || button_b_title}>
            <Heading as="h1" content={striptags(title.html)} />
            <Text content={subtitle} />
            {(button_a_title ||
              button_a_link ||
              button_b_title ||
              button_b_link) && (
              <ButtonGroup>
                <Link to={`${button_a_link}/`}>
                  <Button className="primary" title={button_a_title} />
                </Link>
                <Link to={`${button_b_link}/`}>
                  <Button className="sec" title={button_b_title} />
                </Link>
              </ButtonGroup>
            )}
          </BannerContent>
        </Container>
      </Overlay>
    </BannerWrapper>
  )
}

export default Banner
