import OneColumnContentSectionWrapper, {
  Content,
  ContentWrapper,
} from "./oneColumnContent.style"

import Container from "../../../../components/UI/Container"
import React from "react"
import TitleSection from "../../../TitleSection"
import { addLazyLoadingToImages } from "../../../../utils/addLazyLoadingToImages"

const OneColumnContent = ({ content }) => {
  const { primary, items } = content
  const parsedHtml = addLazyLoadingToImages(items[0].free_content.html)

  return (
    <OneColumnContentSectionWrapper>
      <Container width="1220px">
        <ContentWrapper>
          {primary.title1.html && primary.title_colored.html ? (
            <TitleSection
              titleA={primary.title1.html}
              titleB={primary.title_colored.html}
            />
          ) : null}
          <Content>
            <div
              dangerouslySetInnerHTML={{ __html: parsedHtml }}
              className="content"
            />
          </Content>
        </ContentWrapper>
      </Container>
    </OneColumnContentSectionWrapper>
  )
}

export default OneColumnContent
