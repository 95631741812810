import "rc-tabs/assets/index.css"

import React from "react"
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar"
import TabContent from "rc-tabs/lib/TabContent"
import Tabs, { TabPane } from "rc-tabs"
import { Link } from "gatsby"

import Button from "../../../../components/Button"
import Container from "../../../../components/UI/Container"
import Text from "../../../../components/Text"
import TitleSection from "../../../TitleSection"
import arrow from "../../../../common/assets/image/alpacked/services/arrowRight.svg"
import SectionWrapper, { Content, Details, Section } from "./related.style"

const Related = ({ content }) => {
  const { primary, items, __typename } = content
  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
        />
        <Tabs
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent animated={false} />}
          className="update-screen-tab"
        >
          {items.map((item, index) => (
            <TabPane
              tab={<>{item.related_service_title.text.toUpperCase()}</>}
              key={index + 1}
            >
              <Section>
                <Details>
                  <div className="intro_section">
                    <div className="intro">
                      <img
                        loading="lazy"
                        src={item.icon.url}
                        alt="service icon"
                      />
                      <span>{item.related_service_title.text}</span>
                    </div>
                    <Text content={item.intro} className="intro_text" />
                  </div>
                  <Link
                    to={
                      __typename === "PrismicServicesPageBodyRelated"
                        ? `/services/${item.link_to_service.slug}/`
                        : `/consulting/${item.link_to_service.slug}/`
                    }
                  >
                    <Button
                      className="primary"
                      title="SHOW MORE"
                      icon={<img loading="lazy" src={arrow} alt="arrow" />}
                      marginAuto
                    />
                  </Link>
                </Details>
                <Content>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.content.html }}
                  />
                  <Link to={`/services/${item.link_to_service.slug}/`}>
                    <Button className="primary" title="SHOW MORE" />
                  </Link>
                </Content>
              </Section>
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </SectionWrapper>
  )
}

export default Related
