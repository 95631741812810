import React from "react"
import PropTypes from "prop-types"
import Box from "../../../../components/Box"
import Text from "../../../../components/Text"
import Heading from "../../../../components/Heading"
import FeatureBlock from "../../../../components/FeatureBlock"
import Container from "../../../../components/UI/Container"
import TitleSection from "../../../../containers/TitleSection"
import NumberedListWrapper from "./numberedList.style"

const NumberedListSection = ({
  row,
  col,
  whyUsTitle,
  number,
  whyUsDescription,
  contentStyle,
  blockWrapperStyle,
  content,
}) => {
  const { primary, items } = content

  return (
    <NumberedListWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
          subtitle={primary.subtitle.html}
          subTitleMaxWidth="544px"
        />
        <Box className="row" {...row}>
          {items.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <FeatureBlock
                number={
                  <Heading
                    content={("0" + (index + 1)).toString()}
                    {...number}
                  />
                }
                wrapperStyle={blockWrapperStyle}
                contentStyle={contentStyle}
                title={
                  <Heading content={feature.title1.text} {...whyUsTitle} />
                }
                description={
                  <Text
                    content={feature.numberedListContent}
                    {...whyUsDescription}
                  />
                }
                className="whyUs"
              />
            </Box>
          ))}
        </Box>
      </Container>
    </NumberedListWrapper>
  )
}

// NumberedListSection style props
NumberedListSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  whyUsTitle: PropTypes.object,
  number: PropTypes.object,
  whyUsDescription: PropTypes.object,
  blockWrapperStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  content: PropTypes.object,
}

// NumberedListSection default style
NumberedListSection.defaultProps = {
  // NumberedListSection row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // NumberedListSection col default style
  // col: {
  //   width: ["100%", "47%", "28%", "27.7%"],
  // },
  // NumberedListSection block wrapper default style
  blockWrapperStyle: {
    // pb: ["220px", "20px", "20px", "30px"],
    // pt: ["20px", "20px", "20px", "30px"],
  },

  // NumberedListSection content default style
  contentStyle: {
    textAlign: "left",
  },
  // NumberedListSection title default style
  whyUsTitle: {
    fontSize: ["18px", "20px", "22px", "22px"],
    fontWeight: "700",
    color: "#000",
    mb: ["24px", "16px", "16px", "16px"],
    minHeight: ["30px", "50px"],
  },
  // NumberedListSection number default style
  number: {
    textAlign: "center",
    lineHeight: "1.8",
    display: "inline-block",
    fontSize: ["18px", "18px"],
    fontWeight: "700",
    color: "#000",
    mb: ["20px", "20px", "32px", "32px"],
    border: "1px solid #000",
    width: "65px",
    height: "65px",
    p: "15px",
  },
  // NumberedListSection description default style
  whyUsDescription: {
    fontWeight: "300",
    fontSize: ["16px", "18px"],
    lineHeight: "1.5",
    color: "#000",
  },
}

export default NumberedListSection
