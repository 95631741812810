import React from "react"
import striptags from "striptags"

import Container from "../../../../components/UI/Container"
import TableSectionWrapper from "./table.style"
import TitleSection from "../../../TitleSection"

const TableSection = ({ content }) => {
  const { primary, items } = content

  return (
    <TableSectionWrapper
      id={`${striptags(primary.title1.html)}${striptags(
        primary.title_colored.html
      )}`}
    >
      <Container width="1220px">
        {primary.title1.html ||
        primary.title_colored.html ||
        primary.desTable.htmml ? (
          <div className="header">
            <TitleSection
              titleA={primary.title1.html}
              titleB={primary.title_colored.html}
            />
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: primary.desTable.html }}
            />
          </div>
        ) : null}
        <table className="table">
          <thead>
            <tr>
              <th>{primary.table_head_column_a}</th>
              <th>{primary.table_head_column_b}</th>
              {primary.table_head_column_c !== null && (
                <th>{primary.table_head_column_c}</th>
              )}
              {primary.table_head_column_d !== null && (
                <th>{primary.table_head_column_d}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {items.map((row, index) => {
              return (
                <tr key={index}>
                  {row.row_column_a?.html !== "" ? (
                    <td
                      className="first_col"
                      dangerouslySetInnerHTML={{
                        __html:
                          row.row_column_a?.html ||
                          row.row_column_a ||
                          row.raw_column_a?.html,
                      }}
                    />
                  ) : null}
                  {row.raw_column_b?.html !== "" ? (
                    <td
                      className="first_col"
                      dangerouslySetInnerHTML={{
                        __html: row.raw_column_b?.html || row.raw_column_b,
                      }}
                    />
                  ) : null}
                  {row.raw_column_c?.html && (
                    <td
                      className="first_col"
                      dangerouslySetInnerHTML={{
                        __html: row.raw_column_c?.html || row.raw_column_c,
                      }}
                    />
                  )}
                  {row.raw_column_d?.html && (
                    <td
                      className="first_col"
                      dangerouslySetInnerHTML={{
                        __html: row.raw_column_d?.html || row.raw_column_d,
                      }}
                    />
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Container>
    </TableSectionWrapper>
  )
}

export default TableSection
