import React from "react"
import striptags from "striptags"

import Services from "../../../../components/Services"
import Container from "../../../../components/UI/Container"
import TitleSection from "../../../TitleSection"
import SectionWrapper, { FeatureWrapper } from "./servicesAlt.style"

const ServicesAlt = ({ content }) => {
  const { primary, items } = content

  return (
    <SectionWrapper
      id={`${striptags(primary.title1.html)}${striptags(
        primary.title_colored.html
      )}`}
    >
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
          subtitle={primary.desServices?.html}
        />
        <FeatureWrapper>
          {items.map((item, index) => (
            <Services
              key={`service_key${index}`}
              thumbUrl={item.image1.url}
              title={item.service_name.text}
              details={item.desServices.text}
            />
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default ServicesAlt
