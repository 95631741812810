import "react-multi-carousel/lib/styles.css"

import Carousel from "react-multi-carousel"
import Container from "../../../../components/UI/Container"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import SectionWrapper from "./cases.style"
import TitleSection from "../../../TitleSection"

const Cases = ({ sectionWrapper, row, content }) => {
  const options = {
    settings: {
      autoplaySpeed: 0,
      disableWheelControls: true,
      disablePanzoom: true,
      overlayColor: "rgba(0, 0, 0, 0.8)",
    },
    buttons: {
      iconColor: "#21AAD4",
    },
    thumbnails: {
      showThumbnails: false,
    },
    caption: {
      showCaption: false,
    },
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const { items, primary } = content
  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
          subtitle={primary.description}
        />
        <div className="case_container">
          <SRLWrapper options={options}>
            <Carousel responsive={responsive}>
              {items.map((item, index) => {
                return (
                  <Image
                    fadeIn={false}
                    loading="lazy"
                    fluid={item.image1.localFile.childImageSharp.fluid}
                    alt="cases image"
                    className="case_image"
                  />
                )
              })}
            </Carousel>
          </SRLWrapper>
        </div>
      </Container>
    </SectionWrapper>
  )
}

Cases.propTypes = {
  row: PropTypes.object,
  content: PropTypes.object,
}

Cases.defaultProps = {
  row: {
    flexBox: true,
    flexDirection: ["column", "row"],
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    mx: ["0px", "0px"],
    mt: ["50px", "60px", "80px", "80px"],
  },
}

export default Cases
