import styled from "styled-components"

const NumberedListWrapper = styled.section`
  padding: 60px 0 60px;
  @media only screen and (max-width: 1220px) {
    padding: 50px 0 50px;
  }
  @media only screen and (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0 25px;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 22px 60px;
    @media only screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 22px 50px;
    }
    @media only screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
    }
  }

  .col {
    max-width: 416px;
    text-align: left;
    min-height: 200px !important;
  }

  .whyUs {
    h2 {
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .feature__block {
    position: relative;
    height: 100%;
    @media (max-width: 480px) {
      padding-top: 20px;
    }
  }
`

export default NumberedListWrapper
