import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const TableSectionWrapper = styled.section`
  background-color: ${themeGet("colors.lightGrey")};
  padding: 60px 0 60px;
  @media (max-width: 1220px) {
    padding: 50px 0 50px;
  }
  @media (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media (max-width: 480px) {
    padding: 25px 0 25px;
  }

  .container {
    overflow: auto;
  }

  .header {
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 990px) {
      flex-direction: column;
    }
  }

  .description {
    margin: -20px 0 36px;
    @media only screen and (max-width: 480px) {
      margin: 16px 0 16px;
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0;
      color: #000;
      width: 100%;
      @media only screen and (max-width: 990px) {
        font-size: 22px;
        line-height: 32px;
        margin-top: -8px;
        margin-bottom: 24px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  td p {
    margin-top: 0px;
  }

  .table {
    overflow: auto;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    min-width: 1000px;
    border-spacing: 0px 50px;
  }

  pre {
    overflow: auto;
    background-color: #252528;
    color: white;
    padding: 10px;
  }

  .first_col {
    @media only screen and (max-width: 990px) {
      width: auto;
    }
  }
  .second_col {
    @media only screen and (max-width: 990px) {
      width: auto;
    }
  }

  .table td,
  .table th {
    border: 8px solid ${themeGet("colors.lightGrey")};
  }

  .table th {
    padding: 40px 40px;
    font-size: 20px;
    @media only screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  .table td {
    vertical-align: top;
    padding: 24px 0px 24px 40px;
    font-weight: 300;
    font-size: 16px;
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      padding: 24px 16px;
    }
  }

  .table tr:nth-child(odd) {
    background-color: none;
  }

  .table tr:nth-child(even) {
    background-color: white;
  }

  .table th {
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: left;
    background-color: ${themeGet("colors.mainBlue")};
    color: white;
    @media only screen and (max-width: 480px) {
      padding: 16px;
    }
  }
`

export default TableSectionWrapper
