import styled from "styled-components"

const SectionWrapper = styled.div`
  background-color: ${props => props.bgColor};
  padding: 50px 0px 50px 0px;
  margin: 90px 0 90px 0;
  @media only screen and (max-width: 480px) {
    margin: 50px 0 50px 0;
    padding: 30px 0px 30px 0px;
  }

  .container {
    .intro {
      margin-bottom: 40px;
      @media only screen and (max-width: 480px) {
        margin-bottom: 30px;
      }

      .seperator {
        width: 175px;
        border-top: 4px solid
          ${props =>
            props.bgColor === "#fff"
              ? "#0E93BC"
              : props.bgColor === "#0E93BC"
              ? "#fff"
              : "#fff"};
        margin-bottom: 12px;
        margin-top: 0px;
        margin-left: 0;
        @media only screen and (max-width: 480px) {
          width: 100px;
        }
      }

      h2 {
        font-size: 34px;
        font-weight: 700;
        color: ${props =>
          props.bgColor === "#fff"
            ? "#0E93BC"
            : props.bgColor === "#0E93BC"
            ? "#fff"
            : "#fff"};
        margin-top: 0px;
        margin-bottom: 16px;
        @media only screen and (max-width: 480px) {
          font-size: 26px;
          margin-bottom: 8px;
        }
      }

      p {
        color: ${props => (props.bgColor === "#fff" ? "#000" : "#fff")};
        margin: 0px;
        font-weight: 300;
      }
    }

    .columns {
      display: flex;
      gap: 16px;
      @media only screen and (max-width: 880px) {
        flex-direction: column;
      }

      .column-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;
        @media only screen and (max-width: 880px) {
          width: 100%;
        }
      }

      .full-height {
        flex: 1;
      }

      .column-content {
        height: 100%;
        background-color: ${props =>
          props.bgColor === "#fff"
            ? "#f9f9f9"
            : props.bgColor === "#0E93BC"
            ? "#40A8C8"
            : "#19191A"};
        padding-bottom: 40px;
        @media only screen and (max-width: 480px) {
          padding-bottom: 24px;
        }

        .numbers {
          font-size: 54px;
          font-weight: 700;
          padding: 40px 30px 16px 30px;
          color: ${props =>
            props.bgColor === "#fff"
              ? "rgba(187,187,187,0.4)"
              : "rgba(255,255,255,0.4)"};
          @media only screen and (max-width: 480px) {
            font-size: 48px;
            padding: 32px 16px 16px 16px;
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 18px;
          font-weight: 700;
          color: ${props => (props.bgColor === "#fff" ? "#000" : "#fff")};
          margin-top: 0px;
          margin-bottom: 8px;
          padding: 16px 30px 0px 30px;
          @media only screen and (max-width: 480px) {
            font-size: 16px;
            margin-bottom: 16px;
            padding: 8px 20px 0px 20px;
          }
        }
        p {
          color: ${props => (props.bgColor === "#fff" ? "#000" : "#fff")};
          margin-top: 0px !important;
          font-weight: 300;
          font-size: 16px;
          margin-top: 12px;
          line-height: 24px;
          margin-bottom: 0px;
          padding: 8px 30px 0px 30px;
          @media only screen and (max-width: 990px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 14px;
            line-height: 22px;
            padding: 8px 20px 0px 20px;
          }
        }

        a {
          color: ${props => (props.bgColor === "#fff" ? "#000" : "#fff")};
          margin-top: 0px !important;
          font-weight: 600;
          font-size: 16px;
          margin-top: 12px;
          line-height: 24px;
          margin-bottom: 0px;
          @media only screen and (max-width: 990px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 14px;
            line-height: 22px;
          }
        }

        p.block-img {
          padding: 12px 30px 0 30px;
          @media only screen and (max-width: 480px) {
            padding: 12px 20px 0px 20px;
          }
        }

        ul {
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px;
          padding: 8px 30px 0px 30px;
          @media only screen and (max-width: 480px) {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 16px;
            padding: 8px 20px 0px 20px;
          }
          li {
            color: ${props => (props.bgColor === "#fff" ? "#000" : "#fff")};
            font-weight: 300;
            margin-bottom: 4px;
            list-style-type: disc;
            list-style-position: inside;
            padding-left: 20px;
            text-indent: -21px;
            @media only screen and (max-width: 990px) {
              margin: 0px 0;
            }
            @media only screen and (max-width: 990px) {
              padding-left: 20px;
              text-indent: -19px;
            }
          }
        }
      }
    }
  }
`

export default SectionWrapper
