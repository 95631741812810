import React from "react"
import striptags from "striptags"
import { Link } from "gatsby"

import Arrow from "../../../../common/assets/image/alpacked/services/arrow.svg"
import Container from "../../../../components/UI/Container"
import Heading from "../../../../components/Heading"
import Text from "../../../../components/Text"
import Section, {
  ServiceContent,
  ServiceWrapper,
} from "./relatedServices.style"

const RelatedServices = ({ content }) => {
  return (
    <Section>
      <Container width="1220px">
        <ServiceWrapper>
          <ServiceContent>
            {content.primary.title1.html && (
              <Heading
                as="h2"
                content={striptags(content.primary.title1.html)}
                className="title"
              />
            )}
            <div className="related">
              {content.items.map((item, index) => {
                return (
                  <div className="service-item" key={index}>
                    <div>
                      <img
                        loading="lazy"
                        src={item.icon.url}
                        alt="service icon"
                        className="service-icon"
                      />
                      <Link
                        to={
                          item?.service_title?.document?.__typename ===
                          "PrismicServicesPage"
                            ? `/services/${item.service_title.uid}/`
                            : item?.service_title?.document?.__typename ===
                              "PrismicConsulting"
                            ? `/consulting/${item?.service_title.uid}/`
                            : `/technologies/${item?.service_title.uid}/`
                        }
                      >
                        <Text
                          content={
                            item?.service_title?.document?.data?.title?.text
                          }
                          className="service-titles"
                        />
                      </Link>
                    </div>
                    <img
                      loading="lazy"
                      src={Arrow}
                      alt="arrow"
                      className="arrow"
                    />
                  </div>
                )
              })}
            </div>
          </ServiceContent>
        </ServiceWrapper>
      </Container>
    </Section>
  )
}

export default RelatedServices
