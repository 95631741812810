import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BannerWrapper = styled.div`
  margin-top: 76px;
  max-height: 680px;
  overflow: hidden;
  background-image: ${props => props.image && `url(${props.image})`};
  background-color: ${themeGet("colors.black")};
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  @media only screen and (max-width: 1340px) {
    margin-top: 56px;
  }
  @media only screen and (max-width: 1099px) {
    min-height: auto;
  }
`

export const BannerContent = styled.div`
  width: 100%;
  margin-top: 120px;
  @media only screen and (max-width: 991px) {
    flex-shrink: 0;
    margin-top: 70px;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 90px;
  }

  .date {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;

    @media only screen and (max-width: 990px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
    }
  }

  h1 {
    font-size: 70px;
    line-height: 80px;
    font-weight: 700;
    margin-bottom: 32px;
    color: ${themeGet("colors.white")};
    @media only screen and (max-width: 990px) {
      line-height: 60px;
      font-size: 50px;
    }
    @media only screen and (max-width: 480px) {
      line-height: 40px;
      font-size: 34px;
      margin-bottom: 0px;
    }
  }

  p {
    max-width: 600px;
    color: ${themeGet("colors.white")};
    font-size: 28px;
    line-height: 40px;

    @media only screen and (max-width: 990px) {
      margin-top: 40px;
      font-size: 24px;
      line-height: 36px;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 24px;
      font-size: 20px;
      line-height: 30px;
    }
  }
`

export const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  @media only screen and (max-width: 480px) {
    min-height: 75vh;
  }
  > div.container {
    display: flex;
  }
`

export default BannerWrapper
