import SectionWrapper, { ContentWrapper } from "./relatedArticles.style"

import Button from "../../components/Button"
import CaseStudyPost from "../../components/CaseStudyPost"
import Container from "../../components/UI/Container"
import { Link } from "gatsby"
import React from "react"
import TitleSection from "../TitleSection"

const RelatedCaseStudies = ({ content }) => {
  return (
    <>
      {content.items.length > 0 && (
        <SectionWrapper>
          <Container width="1220px">
            <div className="title-intro">
              <TitleSection titleA="Read" titleB="other cases" />
              <Link to="/case-studies">
                <Button className="outline" title="GO TO CASE STUDIES" />
              </Link>
            </div>
            <ContentWrapper>
              <div className="infinite">
                {content.items.map(blogPost => {
                  const {
                    uid,
                    tags,
                    id,
                    data: { image },
                    data: { title },
                  } = blogPost.related_case_studies.document
                  return (
                    <CaseStudyPost
                      slug={uid}
                      tags={tags}
                      key={id}
                      thumbUrl={image.localFile?.childImageSharp.fluid}
                      title={title.text}
                    />
                  )
                })}
              </div>
            </ContentWrapper>
          </Container>
        </SectionWrapper>
      )}
    </>
  )
}

export default RelatedCaseStudies
