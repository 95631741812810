import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

import Arrow from "../../../../common/assets/image/alpacked/services/more.svg"
import Button from "../../../../components/Button"
import Container from "../../../../components/UI/Container"
import Services from "../../../../components/Services"
import Text from "../../../../components/Text"
import TitleSection from "../../../TitleSection"
import SectionWrapper, { Discover, FeatureWrapper } from "./services.style"

const ServicesSection = ({ content }) => {
  const { primary, items, __typename } = content

  const data = useStaticQuery(graphql`
    query Count {
      allPrismicServicesPage {
        totalCount
      }
      allPrismicConsulting {
        totalCount
      }
    }
  `)

  const { totalCount: totalServices } = data.allPrismicServicesPage
  const { totalCount: totalConsulting } = data.allPrismicConsulting

  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
          subtitle={primary.description.html}
        />
        <FeatureWrapper numberOfColumns={primary.number_of_columns}>
          {items.map((item, index) => (
            <Services
              key={`service_key${index}`}
              thumbUrl={item.image.url}
              title={item.service_name.text}
              details={item.description.html}
            />
          ))}
        </FeatureWrapper>
        <Discover>
          <div className="text">
            <Text content="+" className="plus" />
            <Text
              content={
                __typename === "PrismicConsultingBodyServices"
                  ? `${totalConsulting + totalServices - 1}`
                  : `${totalConsulting + totalServices - 1}`
              }
              className="count"
            />
            <Text
              content={
                __typename === "PrismicConsultingBodyServices"
                  ? `services`
                  : `services`
              }
              className="extra"
            />
          </div>
          <Link to="/services/">
            <Button
              icon-Position="left"
              icon={
                <img
                  loading="lazy"
                  src={Arrow}
                  alt="arrow"
                  iconposition="left"
                />
              }
              className="button"
              title="DISCOVER THEM ALL"
              textAlign="left"
            />
          </Link>
        </Discover>
      </Container>
    </SectionWrapper>
  )
}

export default ServicesSection
