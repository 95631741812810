import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const FaqSectionWrapper = styled.section`
  padding: 60px 0 60px;
  @media only screen and (max-width: 1220px) {
    padding: 50px 0 50px;
  }
  @media only screen and (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0 25px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 990px) {
      flex-direction: column;
    }
  }

  .questionsRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .details {
      border: 1px solid ${themeGet("colors.black")};
      padding: 60px 86px 60px 368px;
      margin-left: -374px;
      @media only screen and (max-width: 1220px) {
        padding: 50px 60px 50px 368px;
      }
      @media only screen and (max-width: 990px) {
        border: none;
        padding: 0px 0px 0px 0px;
        margin-left: 0px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 480px) {
        border: none;
        padding: 0px 0px 0px 0px;
        margin-left: 0px;
        margin-bottom: 10px;
      }
      .question {
        font-size: 24px;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 8px;
        @media only screen and (max-width: 990px) {
          font-size: 22px;
          margin-bottom: 0px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 20px;
          line-height: 22px;
        }
      }
      .email {
        font-size: 22px;
        line-height: 40px;
        font-weight: 300;
        margin-bottom: 0px;
        @media only screen and (max-width: 990px) {
          font-size: 22px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 20px;
        }
      }
    }
  }

  .reusecore__accordion {
    margin: 0 auto;

    .accordion__item {
      background-color: ${themeGet("colors.lightGrey")};
      margin-bottom: 8px;
      border-top: 0;
      padding: 32px 48px;
      @media only screen and (max-width: 480px) {
        padding: 30px 30px;
      }
      &:last-child {
        margin-bottom: 0px;
      }

      .accordion__header {
        /* padding: 20px 30px; */
      }

      .accordion__body {
        /* padding: 5px 30px 20px; */
        p {
          font-weight: 300;
        }
        ul {
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 48px;
          @media only screen and (max-width: 990px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 16px;
            margin-bottom: 0px;
          }
          li {
            font-weight: 300;
            margin: 20px 0;
            list-style-type: disc;
            list-style-position: inside;
            padding-left: 26px;
            text-indent: -26px;
            @media only screen and (max-width: 990px) {
              margin: 10px 0;
            }
          }
        }

        ol {
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 48px;
          padding-left: 0px;
          @media only screen and (max-width: 990px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 16px;
            margin-bottom: 0px;
          }
          li {
            font-weight: 300;
            margin: 20px 0;
            list-style-type: decimal;
            list-style-position: inside;
            padding-left: 26px;
            text-indent: -26px;
            @media only screen and (max-width: 990px) {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
`

export default FaqSectionWrapper
