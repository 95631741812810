import React from "react"

import Container from "../../../../components/UI/Container"
import Text from "../../../../components/Text"
import TitlesZone from "./TitlesZone"
import SectionWrapper, {
  ContentTable,
  IntroText,
  Section,
} from "./tableOfContents.style"

const TableOfContents = ({ content, titles }) => {
  const { primary } = content
  return (
    <SectionWrapper>
      <Container width="1220px">
        <Section>
          <ContentTable>
            <Text content="TABLE OF CONTENTS" className="title" />
            <ul>
              <TitlesZone titles={titles} />
            </ul>
          </ContentTable>
          <IntroText>
            <div
              dangerouslySetInnerHTML={{ __html: primary.intro_text1.html }}
            />
          </IntroText>
        </Section>
      </Container>
    </SectionWrapper>
  )
}

export default TableOfContents
