import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 60px 0 60px;

  @media (max-width: 1220px) {
    padding: 50px 0 50px;
  }
  @media (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media (max-width: 480px) {
    padding: 25px 0 25px;
  }
`

export const Section = styled.div`
  display: flex;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
  }
  p {
    margin-top: 0px;
    font-weight: 300;
    font-size: 18px;
    @media only screen and (max-width: 990px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
`

export const ContentTable = styled.figure`
  background-color: ${themeGet("colors.lightGrey")};
  width: 36.5%;
  margin: 0 9% 0 0;
  padding: 40px 40px;
  @media only screen and (max-width: 990px) {
    width: 100%;
    margin: 0 0 40px 0;
  }
  @media only screen and (max-width: 990px) {
    padding: 30px;
  }
  .title {
    color: ${themeGet("colors.mainBlue")};
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 32px;
    @media only screen and (max-width: 990px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 20px;
      margin-bottom: 24px;
    }
  }

  li {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 18px;
    transition: all 0.2s ease;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  li:hover {
    color: ${themeGet("colors.mainBlueHover")};
  }

  li span {
    font-weight: 300;
    margin-right: 6px;
  }
`
export const IntroText = styled.div`
  width: 56%;
  p {
    line-height: 34px;
    font-size: 18px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`

export default SectionWrapper
