/* eslint-disable */

import React from "react"

import Consultation from "../../../../containers/Consultation"
import Container from "../../../../components/UI/Container"
import SectionWrapper, { Columns, SectionWrapperAlt } from "./challenges.style"

const Challenges = ({ content, location }) => {
  const { primary, items } = content
  const { background_color, description, titlechallenges } = primary

  return (
    <>
      <SectionWrapper bgColor={background_color}>
        <Container width="1220px">
          <div className="intro">
            <hr className="seperator" />
            <h2>{titlechallenges}</h2>
            <p>{description}</p>
          </div>
          <div className="columns">
            <div className="column-container">
              {items.map((item, index) => {
                return (
                  <>
                    {item.align === "Left" ? (
                      <div className="full-height">
                        <div
                          key={`index-${item.align}`}
                          className="column-content"
                        >
                          <p className="numbers">{index + 1}</p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.content.html,
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                )
              })}
            </div>

            <div className="column-container">
              {items.map((item, index) => {
                return (
                  <>
                    {item.align === "Right" ? (
                      <div className="full-height">
                        <div
                          key={`index-${item.align}`}
                          className="column-content"
                        >
                          <p className="numbers">{index + 1}</p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.content.html,
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                )
              })}
            </div>
          </div>
        </Container>
      </SectionWrapper>
    </>
  )
}

export default Challenges
