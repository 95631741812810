import styled from "styled-components"

const SectionWrapper = styled.div`
  padding: 0px 0 60px;
  @media only screen and (max-width: 1220px) {
    padding: 0px 0 50px;
  }
  @media only screen and (max-width: 990px) {
    padding: 0px 0 40px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0 25px;
  }
  .content {
    ul,
    ol {
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 48px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 0px;
      }
      li {
        font-weight: 300;
        margin: 10px 0;
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 26px;
        text-indent: -26px;
        @media only screen and (max-width: 990px) {
          margin: 10px 0;
        }
      }
    }
    display: flex;
    padding: 0px;
    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }
`

export const ImageWrapper = styled.div`
  width: 60%;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
  .image {
    height: 500px;
    margin-bottom: 40px;
    @media only screen and (max-width: 480px) {
      height: 300px;
      margin-bottom: 10px;
    }
  }
  .contentImage {
    display: flex;
    flex-direction: column;
    p {
      margin: 0px 0px 16px 0px;
      font-size: 18px;
      font-weight: 300;
      line-height: 40px;
      @media only screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 900px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 18px;
        margin: 0px 0px 8px 0px;
        line-height: 28px;
        &::last-child {
          margin: 0px 0px 0px 0px;
        }
      }
    }
  }
`

export const TextWrapper = styled.div`
  width: 40%;
  margin: ${props =>
    props.position === "right" ? "0 80px 0 0px" : "0 0px 0 80px"};
  @media only screen and (max-width: 1100px) {
    margin: 0px;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    p {
      margin: 0px 0px 24px 0px;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
      @media only screen and (max-width: 900px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        margin: 0px 0px 16px 0px;
        line-height: 28px;
        &::last-child {
          margin: 0px 0px 0px 0px;
        }
      }
    }
    ul,
    ol {
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 48px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 0px;
      }
      li {
        font-weight: 300;
        margin: 10px 0;
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 26px;
        text-indent: -26px;
        @media only screen and (max-width: 990px) {
          margin: 10px 0;
        }
      }
    }
  }
`

export default SectionWrapper
