import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  width: 100%;
  margin: 60px 0 60px;
  z-index: 0;
  background-color: ${themeGet("colors.lightGrey")};
  @media only screen and (max-width: 1220px) {
    margin: 50px 0 50px;
  }
  @media only screen and (max-width: 990px) {
    margin: 40px 0 40px;
  }
  @media only screen and (max-width: 480px) {
    margin: 25px 0 25px;
  }

  .container {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: ${themeGet("colors.lightGrey")};
    @media only screen and (max-width: 1220px) {
      padding-top: 80px;
      padding-bottom: 60px;
    }
    @media only screen and (max-width: 990px) {
      padding-top: 60px;
      padding-bottom: 40px;
    }
    @media only screen and (max-width: 480px) {
      padding-top: 40px;
      padding-bottom: 25px;
    }
  }
`

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 0 51px; */
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 1440px) {
    padding: 0;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 -15px;
  }

  .service_post {
    /* width: calc(100% / ${props => props.numberOfColumns} - 75px); */
    width: ${props =>
      props.numberOfColumns % 2 === 0
        ? `calc(100% / ${props.numberOfColumns} - 57px)`
        : `calc(100% / ${props.numberOfColumns} - 50px)`};
    margin-bottom: 40px;
    text-align: left;
    /* padding-right: 10px; */
    @media only screen and (max-width: 1360px) {
      width: calc(100% / 2 - 30px);
      margin: 0 15px 25px;
    }
    @media only screen and (max-width: 991px) {
      width: calc(100%);
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      min-height: 0px;
    }

    .thumbnail {
      img {
        height: 34px;
        width: auto;
        margin-bottom: 24px;
        @media only screen and (max-width: 990px) {
          margin-bottom: 24px;
        }
      }
    }

    .content {

      .titleAlt {
        font-weight: 700;
        font-size: 22px;
      }
      .title {
        font-weight: 700;
        font-size: 22px;
      }

      p {
        min-height: 60px;
        font-size: 18px;
        line-height: 30px;
        margin-top: 0px;
        @media only screen and (max-width: 990px) {
          font-size: 20px;
          margin: 0 0 24px;
          min-height: 20px;
          line-height: 30px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 20px;
          margin: 0 0 16px;
        }
      }

      ul {
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 48px;
        @media only screen and (max-width: 480px) {
          font-size: 16px;
          margin-bottom: 0px;
        }
      }

      li {
        margin: 10px 0;
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 26px;
        text-indent: -26px;
      }
    }
  }
`

export default SectionWrapper
