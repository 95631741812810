import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const TestimonialSectionWrapper = styled.section`
  padding: 60px 0 60px;

  @media (max-width: 1220px) {
    padding: 50px 0 50px;
  }
  @media (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media (max-width: 480px) {
    padding: 25px 0 25px;
  }
  .quote_icon {
    padding: 0px 160px;
    margin-bottom: -35px;
    @media (max-width: 1220px) {
      padding: 0px 80px;
    }
    @media (max-width: 990px) {
      padding: 0px 80px;
    }
    @media (max-width: 480px) {
      padding: 0px 30px;
      width: 100px;
      margin-bottom: -16px;
    }
  }
`

export const Quote = styled.div`
  background-color: ${themeGet("colors.lightGrey")};
  .author {
    display: flex;
    align-items: center;
    padding: 0px 80px 40px 80px;
    @media (max-width: 480px) {
      padding: 10px 30px 30px 30px;
    }
    .author-avatar {
      width: 48px;
      border-radius: 50%;
      margin-right: 24px;
    }
    p {
      color: ${themeGet("colors.black")};
      margin: 0;
    }
    .author-name {
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      line-height: 20px;
    }
    .author-occupation {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .quote_text {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    padding: 50px 80px 10px 80px;

    @media (max-width: 990px) {
      font-size: 22px;
      padding: 80px 80px 40px 80;
    }
    @media (max-width: 480px) {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      padding: 30px 30px 30px 30px;
    }
  }
`
export default TestimonialSectionWrapper
