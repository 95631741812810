import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  width: 100%;
  padding: 60px 0 60px;
  z-index: 0;
  @media only screen and (max-width: 1220px) {
    padding: 50px 0 50px;
  }
  @media only screen and (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0 25px;
  }
`

export const FeatureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 0 51px; */
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 1440px) {
    padding: 0;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 -15px;
  }
  @media only screen and (max-width: 480px) {
    margin: 0 0px;
  }

  .service_post {
    width: calc(100% / 2 - 15px);
    padding: 44px 48px 0px 48px;
    background-color: ${themeGet("colors.lightGrey")};
    margin-bottom: 20px;
    text-align: left;
    flex-wrap: wrap;
    /* min-height: 300px; */
    @media only screen and (max-width: 1360px) {
      width: calc(100% / 2 - 15px);
      margin: 0 0px 25px;
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
      padding: 30px 30px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      min-height: 0px;
    }

    .thumbnail {
      img {
        height: 43px;
        width: auto;
        margin-bottom: 30px;
        @media only screen and (max-width: 990px) {
          margin-bottom: 24px;
        }
      }
    }

    .content {
      h2 {
        margin: 0 0 30px;
        font-size: 24px;
        line-height: 40px;
        font-weight: 700;
        @media only screen and (max-width: 990px) {
          font-size: 22px;
          margin: 0 0 24px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 20px;
          margin: 0 0 16px;
          line-height: 32px;
        }
      }

      ul {
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 48px;
        @media only screen and (max-width: 480px) {
          font-size: 16px;
          margin-bottom: 0px;
        }
      }

      li {
        margin: 10px 0;
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 26px;
        text-indent: -26px;
      }
    }
  }
`

export default SectionWrapper
