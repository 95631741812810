import SectionWrapper, { ImageWrapper, TextWrapper } from "./intro.style"

import Container from "../../../../components/UI/Container"
import Img from "gatsby-image"
import React from "react"
import TitleSection from "../../../TitleSection"

const Intro = ({ content }) => {
  const { primary } = content
  const isImageExist = !!primary.image.localFile

  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
        />
      </Container>
      <Container width="1220px" className="content">
        <TextWrapper
          imageScale={primary.original_image_scale}
          isImageExist={isImageExist}
        >
          <div
            dangerouslySetInnerHTML={{ __html: primary.intro_text.html }}
            className="content"
          />
        </TextWrapper>
        {isImageExist && (
          <ImageWrapper
            imageScale={primary.original_image_scale}
            isImageExist={isImageExist}
          >
            <Img
              fadeIn={false}
              loading="lazy"
              className="image"
              fluid={primary.image.localFile?.childImageSharp.fluid}
              alt="intro-img"
            />
          </ImageWrapper>
        )}
      </Container>
    </SectionWrapper>
  )
}

export default Intro
