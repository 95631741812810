import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  margin: 45px 0 90px 0;
  @media only screen and (max-width: 480px) {
    margin: 30px 0 50px 0;
  }

  .container {
    display: flex;
    gap: 20px;
    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
`

export const ColumnInfo = styled.div`
  background: #f9f9f9;
  min-width: 426px;
  max-width: 426px;
  padding: 40px 30px;
  @media only screen and (max-width: 800px) {
    min-width: 100%;
    max-width: 100%;
    padding: 20px 20px;
  }

  .intro {
    margin-bottom: 72px;
    @media only screen and (max-width: 990px) {
      margin-bottom: 50px;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  .intro_extra {
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media only screen and (max-width: 480px) {
      gap: 25px;
    }
  }

  .seperator {
    border-top: 4px solid ${themeGet("colors.mainBlue")};
    margin-bottom: 12px;
    width: 150px;
    margin-left: 0;
    @media only screen and (max-width: 480px) {
      width: 100px;
    }
  }

  h2 {
    font-size: 34px;
    font-weight: 700;
    color: ${themeGet("colors.mainBlue")};
    margin-top: 0px;

    @media only screen and (max-width: 480px) {
      font-size: 26px;
    }
  }

  .project_description {
    margin-top: 0px;
    font-weight: 300;
  }

  .project_subheading {
    color: ${themeGet("colors.black")};
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .project_info {
    margin-top: 0px;
    font-weight: 600;
    color: ${themeGet("colors.mainBlue")};
    font-size: 22px;
    margin-bottom: 0px;
    @media only screen and (max-width: 480px) {
      font-size: 18px;
    }
  }
`

export const ColumnClient = styled.div`
  background: #f9f9f9;
  padding: 40px 30px;
  width: 100%;
  @media only screen and (max-width: 480px) {
    width: 100%;
    padding: 20px 20px;
  }

  p.block-img {
    display: flex;
    justify-content: center;
  }

  img {
    max-width: 400px;
    @media only screen and (max-width: 480px) {
      max-width: 100%;
    }
  }

  h2 {
    font-size: 34px;
    font-weight: 700;
    color: ${themeGet("colors.mainBlue")};
    margin-top: 0px;
    @media only screen and (max-width: 480px) {
      font-size: 26px;
    }
  }
  p {
    margin-top: 0px !important;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 28px;
    line-height: 30px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  ul {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 24px;

    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    li {
      font-weight: 300;
      margin-bottom: 4px;
      list-style-type: disc;
      list-style-position: inside;
      padding-left: 26px;
      text-indent: -26px;
      @media only screen and (max-width: 990px) {
        margin: 0px 0;
      }
      @media only screen and (max-width: 990px) {
        padding-left: 23px;
        text-indent: -23px;
      }
    }
  }
`

export const Benefits = styled.div`
  background: white;
  padding: 30px 30px;
  @media only screen and (max-width: 480px) {
    padding: 20px 20px;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 24px;
    color: ${themeGet("colors.mainBlue")};
    font-size: 22px;
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      margin-bottom: 18px;
    }
  }

  img {
    background: ${themeGet("colors.mainBlue")};
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    padding: 4px;
  }

  .keypoints-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 16px;
    align-items: center;
    @media only screen and (max-width: 990px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }

  .keypoints {
    display: flex;
    gap: 12px;
    align-items: center;

    p {
      font-size: 14px;
      margin: 0;
      line-height: 18px;
    }
  }
`

export default SectionWrapper
