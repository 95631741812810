import SectionWrapper, { ContentWrapper } from "./relatedArticles.style"

import Arrow from "../../common/assets/image/alpacked/authors/arrow_blog.svg"
import BlogPost from "../../components/BlogPost"
import Container from "../../components/UI/Container"
import { Link } from "gatsby"
import React from "react"
import TitleSection from "../TitleSection"

const RelatedArticles = ({ content }) => {
  return (
    <>
      {content.items.length > 0 && (
        <SectionWrapper>
          <Container width="1220px">
            <TitleSection titleA="Read other" titleB="articles" />
            <ContentWrapper>
              <div className="infinite">
                {content.items.map(blogPost => {
                  const {
                    author,
                    category,
                    image,
                    title,
                    intro_text,
                  } = blogPost.related_blog_posts.document.data
                  return (
                    <BlogPost
                      slug={blogPost.related_blog_posts.document.uid}
                      date={
                        blogPost.related_blog_posts.document
                          .first_publication_date
                      }
                      tags={blogPost.related_blog_posts.document.tags}
                      key={blogPost.related_blog_posts.document.id}
                      category={category}
                      author={author}
                      thumbUrl={image.localFile?.childImageSharp.fluid}
                      title={title.text}
                      excerpt={intro_text}
                      link={
                        <Link
                          to={`/blog/${blogPost.related_blog_posts.document.uid}/`}
                          className="excerptLink"
                        >
                          SHOW MORE
                          <img
                            loading="lazy"
                            src={Arrow}
                            alt="arrow"
                            className="arrow"
                          />
                        </Link>
                      }
                    />
                  )
                })}
              </div>
            </ContentWrapper>
          </Container>
        </SectionWrapper>
      )}
    </>
  )
}

export default RelatedArticles
