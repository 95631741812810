import FreeContentSectionWrapper, {
  Content,
  ContentWrapper,
} from "./freeContent.style"

import Container from "../../../../components/UI/Container"
import React from "react"
import TitleSection from "../../../TitleSection"
import { addLazyLoadingToImages } from "../../../../utils/addLazyLoadingToImages"
import striptags from "striptags"

const FreeContent = ({ content }) => {
  const { items } = content

  return (
    <FreeContentSectionWrapper>
      <Container width="1220px">
        {items.map((item, index) => {
          const parsedHtml = addLazyLoadingToImages(item.free_content.html)
          return (
            <ContentWrapper
              key={index}
              id={`${striptags(item.title1.html)}${striptags(
                item.title_colored.html
              )}`}
            >
              {item.title1.html || item.title_colored.html ? (
                <TitleSection
                  titleMaxWidth={item.title_width}
                  titleA={item.title1.html}
                  titleB={item.title_colored.html}
                />
              ) : null}
              <Content imagePosition={item.image_position}>
                <div
                  dangerouslySetInnerHTML={{ __html: parsedHtml }}
                  className="content"
                />
              </Content>
            </ContentWrapper>
          )
        })}
      </Container>
    </FreeContentSectionWrapper>
  )
}

export default FreeContent
