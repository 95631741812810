/* eslint-disable */

import SectionWrapper, {
  ItemContainer,
  StyledImg,
} from "./projectDetails.style"

import Container from "../../../../components/UI/Container"
import Img from "gatsby-image"
import React from "react"

const ProjectDetails = ({ content }) => {
  const { items } = content
  const isASingleColumn = items.length === 1

  return (
    <>
      <SectionWrapper>
        <Container width="1220px">
          <div>
            {items.map((item, index) => {
              return (
                <div key={index}>
                  <ItemContainer
                    isASingleColumn={isASingleColumn}
                    index={index}
                  >
                    <StyledImg position={item.image_position}>
                      <Img
                        fluid={item.image1.localFile.childImageSharp.fluid}
                        alt={item.title1}
                        className="image"
                        fadeIn={false}
                        loading="lazy"
                      />
                    </StyledImg>
                    <h2>{item.title1}</h2>
                    <p
                      dangerouslySetInnerHTML={{ __html: item.content.html }}
                    />
                  </ItemContainer>
                </div>
              )
            })}
          </div>
        </Container>
      </SectionWrapper>
    </>
  )
}

export default ProjectDetails
