import React from "react"
import striptags from "striptags"

import Container from "../../../../components/UI/Container"
import Services from "../../../../components/Services"
import TitleSection from "../../../TitleSection"
import SectionWrapper, { BenefitsWrapper } from "./benefits.style"

const Benefits = ({ content }) => {
  const { primary, items } = content

  return (
    <SectionWrapper
      id={`${striptags(primary.title1.html)}${striptags(
        primary.title_colored.html
      )}`}
    >
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
          subtitle={primary.desBenefits?.html || primary.description?.html}
          subTitleMaxWidth="760px"
          titleMaxWidth={
            primary.title_width ? `${primary.title_width}px` : null
          }
        />
        <BenefitsWrapper numberOfColumns={primary.number_of_columns || 3}>
          {items.map((item, index) => (
            <Services
              key={`service_key${index}`}
              thumbUrl={item.icon.url}
              title={item.benefit_title.text}
              details={
                item.benefit_description.html
                  ? item.benefit_description.html
                  : item.benefit_description
              }
            />
          ))}
        </BenefitsWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default Benefits
