import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 60px 0 60px;
  @media only screen and (max-width: 1220px) {
    padding: 50px 0 50px;
  }
  @media only screen and (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0 25px;
  }

  @keyframes ScaleInUp {
    from {
      opacity: 0;
      transform: translateY(30px) scale(0.97);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  .update-screen-tab {
    border: 0;
    overflow: initial;
    .rc-tabs-ink-bar {
      display: none !important;
    }
    .rc-tabs-bar {
      border: 0;
    }
    .rc-tabs-nav-container {
      margin-bottom: 2px;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        display: block;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
      &:not(.rc-tabs-nav-container-scrolling) {
        .rc-tabs-nav-scroll {
          width: 100%;
          text-align: center;
          .rc-tabs-nav {
            float: none;
            display: block;
            .rc-tabs-tab {
              display: inline-block;
              float: none;
            }
          }
        }
      }
      .rc-tabs-tab {
        margin-right: 10px;
        width: 33.33333%;
        font-size: 18px;
        color: ${themeGet("colors.mainBlue")};
        font-weight: 700;
        min-width: 230px;
        padding: 30px 0 30px 0;
        text-align: center;
        margin-right: 0;
        transition: 0.25s ease-in-out;
        @media only screen and (max-width: 480px) {
          background-color: transparent;
          font-size: 16px;
        }
        &:hover {
          color: ${themeGet("colors.mainBlueHover")};
        }
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 105px;
          z-index: -1;
          bottom: 0;
          left: 0;
          display: block;
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:after {
          background: ${themeGet("colors.lightBlue")};
          transform: scaleX(0);
          transform-origin: right center 0;
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
        &.rc-tabs-tab-active {
          color: ${themeGet("colors.mainBlueHover")};
          &:after {
            transform: scaleX(1);
            transform-origin: left center 0;
            transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
          }
        }
        > div {
          margin-right: 8px;
        }
        @media (max-width: 1199px) {
          font-size: 16px;
          padding: 0 0 20px 0;
          min-width: 170px;
        }
        @media (max-width: 990px) {
          min-width: auto;
          padding: 0 20px 15px 20px;
        }
        @media (max-width: 767px) {
          font-size: 14px;
          svg {
            width: 20px;
          }
        }
      }
    }
    .rc-tabs-content {
      .rc-tabs-tabpane {
        background-color: ${themeGet("colors.lightBlue")};
        overflow: hidden;
        &.rc-tabs-tabpane-active {
          animation: 0.7s ScaleInUp;
        }
        > img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }

  .rc-tabs-tab-prev-icon,
  .rc-tabs-tab-next-icon {
    font-size: 20px;
    color: ${themeGet("colors.mainBlue")};
    line-height: 1;
    display: block;
  }
`

export const Section = styled.div`
  display: flex;
  padding: 100px 80px;
  justify-content: space-between;

  @media only screen and (max-width: 990px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 990px) {
    padding: 30px 30px;
  }
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
  margin: 0 10% 0 0;
  @media only screen and (max-width: 990px) {
    width: 100%;
    margin: 0 0 0 0;
  }

  .intro {
    display: flex;
    align-items: center;
    img {
      margin-right: 16px;
    }
    span {
      color: ${themeGet("colors.mainBlue")};
      font-size: 18px;
      font-weight: 700;
      @media only screen and (max-width: 990px) {
        font-size: 16px;
      }
    }
  }
  .intro_text {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-top: 18px;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 20px;
      line-height: 30px;
      margin-top: 16px;
    }
  }
  .primary {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-transform: inherit;
    border-radius: 0px;
    padding: 24px 40px;
    font-size: 20px;
    background-color: ${themeGet("colors.mainBlue")};
    color: ${themeGet("colors.white")};
    @media only screen and (max-width: 990px) {
      display: none;
    }
    &.primary {
      &:hover {
        background-color: ${themeGet("colors.mainBlueHover")};
      }
    }
  }
`
export const Content = styled.div`
  width: 57%;
  div {
    p:last-child {
      margin-bottom: 0px;
    }
  }
  p {
    margin-top: 0px;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
  }

  .primary {
    margin-top: 24px;
    margin-bottom: 0px;
    width: 100%;
    text-transform: inherit;
    border-radius: 0px;
    padding: 24px 50px;
    font-size: 18px;
    background-color: ${themeGet("colors.mainBlue")};
    color: ${themeGet("colors.white")};
    @media only screen and (min-width: 991px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      padding: 15px 10px;
      font-size: 18px;
    }
    &.primary {
      &:hover {
        background-color: ${themeGet("colors.mainBlueHover")};
      }
    }
  }
`

export default SectionWrapper
