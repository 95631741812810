import TestimonialSectionWrapper, { Quote } from "./quote.style"

import Container from "../../../../components/UI/Container"
import Img from "gatsby-image"
import QuoteIcon from "../../../../common/assets/image/alpacked/services/quote.svg"
import React from "react"
import Text from "../../../../components/Text"

const TestimonialSection = ({ content }) => {
  return (
    <TestimonialSectionWrapper>
      <Container width="1220px">
        <img
          loading="lazy"
          src={QuoteIcon}
          alt="quote icon"
          className="quote_icon"
        />
        <Quote>
          <Text content={content.primary.quote_text} className="quote_text" />
          {content.primary.author_image && (
            <div className="author">
              {content.primary.author_image?.localFile !== null ? (
                <Img
                  fadeIn={false}
                  loading="lazy"
                  fluid={
                    content.primary.author_image.localFile?.childImageSharp
                      .fluid
                  }
                  alt={content.primary.author_name}
                  className="author-avatar"
                />
              ) : null}
              <div>
                <p className="author-name">{content.primary?.author_name}</p>
                <p className="author-occupation">
                  {content.primary?.author_occupation}
                </p>
              </div>
            </div>
          )}
        </Quote>
      </Container>
    </TestimonialSectionWrapper>
  )
}

export default TestimonialSection
