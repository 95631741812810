import React from "react"

import AlpackedBenefits from "./containers/AlpackedBenefits"
import Banner from "./containers/Banner"
import BannerBlog from "./containers/BannerBlog"
import Benefits from "./containers/Benefits"
import Breadcrumbs from "./containers/Breadcrumbs"
import Cases from "./containers/Cases"
import CasesTwoColumnsText from "./containers/CasesTwoColumnsText"
import Challenges from "./containers/Challenges"
import FAQ from "./containers/FaqSection"
import FreeContent from "./containers/FreeContent"
import FreeContentCaseStudies from "./containers/FreeContentCaseStudies"
import Intro from "./containers/Intro"
import NumberedList from "./containers/NumberedList"
import OneColumnContent from "./containers/OneColumnContent"
import ProjectDetails from "./containers/ProjectDetails"
import ProjectInfo from "./containers/ProjectInfo"
import Quote from "./containers/Quote"
import Related from "./containers/Related"
import RelatedArticles from "../../containers/RealtedArticles"
import RelatedCaseStudies from "../../containers/RealtedCaseStudies"
import RelatedServices from "./containers/RelatedServices"
import Services from "./containers/Services"
import ServicesAlt from "./containers/ServicesAlt"
import Statistics from "./containers/Statistics"
import Table from "./containers/Table"
import TableOfContents from "./containers/TableOfContents"
import Technologies from "./containers/Technologies"
import TwoColumns from "./containers/TwoColumns"

const SlicesZone = ({
  content,
  breadCrumbsContent,
  date,
  author,
  location,
}) => {
  const slice = content.map((s, index) => {
    switch (s.slice_type) {
      // These are the API IDs of the slices
      case "banner":
        return <Banner key={index} content={s} />
      case "case_studies_2_column_text":
        return (
          <CasesTwoColumnsText key={index} content={s} location={location} />
        )
      case "banner_blog":
        return (
          <BannerBlog key={index} content={s} date={date} location={location} />
        )
      case "challenges":
        return <Challenges key={index} content={s} date={date} />
      case "benefits":
        return <AlpackedBenefits key={index} content={s} />
      case "project_details":
        return <ProjectDetails key={index} content={s} />
      case "project_info":
        return <ProjectInfo key={index} content={s} />
      case "benefits1":
        return <Benefits key={index} content={s} />
      case "cases":
        return <Cases key={index} content={s} />
      case "introduction":
        return <Intro key={index} content={s} />
      case "breadcrumbs":
        return (
          <Breadcrumbs
            key={index}
            content={s}
            breadCrumbsContent={breadCrumbsContent}
          />
        )
      case "related":
        return <Related key={index} content={s} />
      case "content":
        return <FreeContent key={index} content={s} />
      case "freetextcasestudies":
        return <FreeContentCaseStudies key={index} content={s} />
      case "numbered_list":
        return <NumberedList key={index} content={s} />
      case "related_services":
        return <RelatedServices key={index} content={s} />
      case "related_blog_posts":
        return <RelatedArticles key={index} content={s} />
      case "related_case_studies_posts":
        return <RelatedCaseStudies key={index} content={s} />
      case "statistics":
        return <Statistics key={index} content={s} />
      case "services":
        return <Services key={index} content={s} />
      case "servicesAlt":
        return <ServicesAlt key={index} content={s} />
      case "table_of_contents":
        return <TableOfContents key={index} content={s} titles={content} />
      case "technologies":
        return <Technologies key={index} content={s} />
      case "table":
        return <Table key={index} content={s} />
      case "two_column_content":
        return <TwoColumns key={index} content={s} />
      case "faq":
        return <FAQ key={index} content={s} />
      case "one_column_content":
        return <OneColumnContent key={index} content={s} />
      case "quote":
        return <Quote key={index} content={s} author={author} />
      default:
        return null
    }
  })

  return <>{slice}</>
}

export default SlicesZone
