import styled from "styled-components"

const SectionWrapper = styled.section`
  padding: 0px 0 40px;
  height: auto;
  overflow: hidden;
  @media only screen and (max-width: 480px) {
    padding: 40px 0 40px;
  }

  .case_container {
    margin: 46px auto 0px auto;
    width: 85%;
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .case_container > div {
    /* position: relative;
    justify-content: center;
    gap: 40px;
    display: flex;
    flex-wrap: wrap; */
  }
  .case_image {
    cursor: pointer;

    /* @media only screen and (max-width: 480px) {
      width: 100%;
    } */
  }
`

export default SectionWrapper
