import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const Section = styled.div`
  padding: 60px 0 0px;
  background-color: ${themeGet("colors.lightBlue")};

  @media (max-width: 1220px) {
    padding: 50px 0 0px;
  }
  @media (max-width: 990px) {
    padding: 40px 0 0px;
  }
  @media (max-width: 480px) {
    padding: 25px 0 0px;
  }
`

export const ServiceWrapper = styled.div`
  background-color: ${themeGet("colors.lightBlue")};

  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ServiceContent = styled.div`
  padding: 60px 0px 60px 0px;
  @media screen and (max-width: 990px) {
    padding: 40px 0px;
  }
  @media screen and (max-width: 480px) {
    padding: 30px 0px;
  }

  .title {
    font-size: 40px;
    text-align: left;
    line-height: 68px;
    margin-bottom: 64px;
    @media screen and (max-width: 990px) {
      font-size: 32px;
    }
    @media screen and (max-width: 480px) {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 40px;
    }
  }

  .related {
    display: flex;
    flex-wrap: wrap;
  }

  .service-item {
    flex: 0 0 calc(50% - 40px);
    margin-bottom: 24px;
    cursor: pointer;
    display: flex;
    &:nth-child(odd) {
      margin-right: 80px;
    }
    @media only screen and (max-width: 768px) {
      flex: 0 0 calc(50% - 25px);
    }
    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
    }
    @media only screen and (max-width: 480px) {
      margin: 0px 0px 24px 0;
    }
  }

  .service-item:hover {
    .arrow {
      width: 44px !important;
    }
    @media screen and (max-width: 578px) {
      width: 34px !important;
    }
  }

  .service-titles {
    font-size: 22px;
    color: ${themeGet("colors.mainBlue")};
    margin-bottom: 0px;
    transition: 0.1s ease-in-out;
    &:hover {
      color: ${themeGet("colors.mainBlueHover")};
    }
    @media screen and (max-width: 990px) {
      font-size: 20px;
    }
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .arrow {
    width: 30px;
    margin-left: auto;
    transition: all 0.2sec ease;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  .service-icon {
    height: 50px;
    width: 50px;
    padding: 15px;
    background-color: ${themeGet("colors.mainBlue")};
    margin-right: 40px;
    @media screen and (max-width: 768px) {
      height: 54px;
      width: 54px;
      margin-right: 20px;
    }
    @media screen and (max-width: 480px) {
      height: 44px;
      width: 44px;
      margin-right: 16px;
    }
  }
  h2 {
    color: ${themeGet("colors.mainBlue")};
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 48px;
    text-align: center;
    @media only screen and (max-width: 990px) {
      font-size: 36px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 34px;
    }
  }

  div {
    display: flex;
    align-items: center;
  }
`

export default Section
