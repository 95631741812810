/* eslint-disable */

import React from "react"

import Container from "../../../../components/UI/Container"
import SectionWrapper, {
  Benefits,
  ColumnClient,
  ColumnInfo,
} from "./projectInfo.style"

const ProjectInfo = ({ content }) => {
  const { primary, items } = content

  return (
    <>
      <SectionWrapper>
        <Container width="1220px">
          <ColumnInfo>
            <div className="intro">
              <hr className="seperator" />
              <h2>{primary.project_title}</h2>
              <p className="project_description">
                {primary.project_description}
              </p>
            </div>
            <div className="intro_extra">
              <div>
                <h3 className="project_subheading">TEAM</h3>
                <p className="project_info">{primary.team}</p>
              </div>
              <div>
                <h3 className="project_subheading">PERIOD OF COLLABORATION</h3>
                <p className="project_info">{primary.period}</p>
              </div>
              <div>
                <h3 className="project_subheading">CLIENT’S LOCATION</h3>
                <p className="project_info">{primary.location}</p>
              </div>
            </div>
          </ColumnInfo>
          <ColumnClient>
            <h2>{primary.client_title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: primary.client_info.html,
              }}
            />
            <Benefits>
              <h3>{primary.benefits_title}</h3>
              <div className="keypoints-wrapper">
                {items.map((item, index) => {
                  return (
                    <div key={index} className="keypoints">
                      <img src={item.icon.url} alt="icon" />
                      <p>{item.keypoint}</p>
                    </div>
                  )
                })}
              </div>
            </Benefits>
          </ColumnClient>
        </Container>
      </SectionWrapper>
    </>
  )
}

export default ProjectInfo
