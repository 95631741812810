import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  position: relative;
  padding: 60px 0px 60px;
  @media only screen and (max-width: 1220px) {
    padding: 50px 0px 50px;
  }
  @media only screen and (max-width: 990px) {
    padding: 40px 0px 40px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0px 25px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-transform: uppercase;
  align-items: center;

  .home {
    font-size: 16px;
    font-weight: 700;
    padding-top: 10px;
    color: ${themeGet("colors.black")};
    margin: 0px;
    margin-right: 16px;
    white-space: nowrap;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-right: 16px;
    }
  }

  img {
    margin-right: 16px;
    width: 10px;
    padding-top: 10px;
    @media only screen and (max-width: 768px) {
      margin-right: 16px;
    }
  }

  .current-page {
    font-size: 16px;
    font-weight: 700;
    padding-top: 10px;
    color: ${themeGet("colors.mainBlue")};
    margin: 0px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
`

export default SectionWrapper
