import React, { useEffect, useState } from "react"
import SectionWrapper, { ImageWrapper, TextWrapper } from "./twoColumns.style"

import Container from "../../../../components/UI/Container"
/* eslint-disable */
import Img from "gatsby-image"
import TitleSection from "../../../TitleSection"

const TwoColumns = ({ content }) => {
  const { primary } = content

  const [hasRan, setHasRan] = useState(false)
  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  })
  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
  }
  useEffect(() => {
    if (!hasRan) {
      setHasRan(true)
      updateScreenSize()
    }
    window.addEventListener("resize", updateScreenSize)
    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [screenSize])

  const ContentImageRight = () => {
    return (
      <Container width="1220px" className="content">
        <TextWrapper position={primary.image_position}>
          <div
            dangerouslySetInnerHTML={{ __html: primary.column_a?.html }}
            className="content"
          />
        </TextWrapper>
        <ImageWrapper>
          {primary.image.localFile !== null && (
            <Img
              fadeIn={false}
              loading="lazy"
              className="image"
              fluid={primary.image.localFile?.childImageSharp.fluid}
              alt="intro-img"
            />
          )}
          <div
            dangerouslySetInnerHTML={{ __html: primary.column_b?.html }}
            className="contentImage"
          />
        </ImageWrapper>
      </Container>
    )
  }

  const ContentImageLeft = () => {
    return (
      <Container width="1220px" className="content">
        <ImageWrapper>
          {primary.image.localFile !== null && (
            <Img
              fadeIn={false}
              loading="lazy"
              className="image"
              fluid={primary.image.localFile?.childImageSharp.fluid}
              alt="intro-img"
            />
          )}
          <div
            dangerouslySetInnerHTML={{ __html: primary.column_a?.html }}
            className="contentImage"
          />
        </ImageWrapper>
        <TextWrapper position={primary.image_position}>
          <div
            dangerouslySetInnerHTML={{ __html: primary.column_b?.html }}
            className="content"
          />
        </TextWrapper>
      </Container>
    )
  }

  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
        />
        {screenSize.width > 1100 ? (
          primary.image_position === "right" ? (
            <ContentImageRight />
          ) : (
            <ContentImageLeft />
          )
        ) : (
          <ContentImageLeft />
        )}
      </Container>
    </SectionWrapper>
  )
}

export default TwoColumns
