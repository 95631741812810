import React from "react"
import striptags from "striptags"

import Container from "../../../../components/UI/Container"
import Heading from "../../../../components/Heading"
import Text from "../../../../components/Text"
import BannerWrapper, { BannerContent, Overlay } from "./bannerBlog.style"

const BannerBlog = ({ content, date, location }) => {
  const { image1, title1, subtitle } = content.primary

  return (
    <BannerWrapper image={image1.url}>
      <Overlay>
        <Container width="1220px">
          <BannerContent>
            {location && !location.pathname.includes("case-studies") && (
              <Text content={date} className="date" />
            )}
            <Heading as="h1" content={striptags(title1.html)} />
            <Text content={subtitle} />
          </BannerContent>
        </Container>
      </Overlay>
    </BannerWrapper>
  )
}

export default BannerBlog
