import PropTypes from "prop-types"
import React from "react"

import Box from "../../../../components/Box"
import Container from "../../../../components/UI/Container"
import Image from "../../../../components/Image"
import TitleSection from "../../../TitleSection"
import { CertificationsImage } from "./technologies.style"

const Technologies = ({ sectionWrapper, row, content }) => {
  const { items, primary } = content
  return (
    <Box {...sectionWrapper} as="section">
      <Container width="1220px">
        <TitleSection
          titleA={primary.title1.html}
          titleB={primary.title_colored.html}
          subtitle={primary.description.html}
        />
        <Box {...row}>
          {items.map((item, index) => (
            <CertificationsImage key={`client-${index}`}>
              <Image src={item.image.url} alt="certifications" />
            </CertificationsImage>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

Technologies.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  content: PropTypes.object,
}

Technologies.defaultProps = {
  sectionWrapper: {
    pb: ["25px", "40px", "50px", "60px", "60px"],
    pt: ["25px", "40px", "50px", "60px", "60px"],
  },
  row: {
    flexBox: true,
    flexDirection: ["row", "row"],
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    mx: ["0px", "0px"],
    mt: ["50px", "60px", "80px", "80px"],
  },
}

export default Technologies
